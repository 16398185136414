// There are upstream dependency conflicts in @instech/components sub dependencies. 
// For now we get rid of @instech/components and duplicate baseThemes
// A question was raised in #frontend chat how to move forward with this
const baseTheme = {
  maritime1: '#1C3775', /* this is a placeholder name */
  maritime2: '#004A73',
  marineBlue: '#003C71',
  marineBlue90: '#194F7F',
  marineBlue80: '#33638D',
  marineBlue75: '#33638D',
  marineBlue70: '#4C769B',
  marineBlue60: '#668AAA',
  marineBlue50: '#7f9db8',
  marineBlue40: '#99B1C6',
  marineBlue30: '#B2C4D4',
  marineBlue20: '#CCD8E3',
  marineBlue15: '#d1dce5', /* technically it's 17-18, but 15 for uniformity */
  marineBlue10: '#E5EBF0',
  marineBlue5: '#F2F5F8',
  deepAzureBlue: '#053262',
  lightBlue: '#ECF1F5',
  whiteBlue: '#D9E3EC',
  mediumBlue: '#93D4F4',
  softBlue: '#4887B1',
  strongBlue: '#2A9FD8',
  blue: '#003A4D',
  white: '#FFF',
  flatWhite: '#F5F5F5',
  darkWhite: '#F8F8F8',
  black: '#000000',
  blackPearl: '#001A32',
  darkGray: '#212121',
  mediumGray: '#C9CDD0',
  lightGray: '#DEDEDE',
  ultraLightGray: '#F3F4F6',
  ultraLightGray2: '#F1F3F6',
  placeholderGray: '#808080',
  green: '#73C6A1',
  green75: '#96d4b9',
  green50: '#b9e2d0',
  darkGreen: '#37C187',
  lightGreen: '#64C698',
  lightRed: '#FE8E9C',
  red: '#AD1F23',
  yellow: '#F6C525',
  lightYellow: '#FFD26C',
  lightYellow30: '#FFF2D3',
  darkYellow: '#FFC43F',
  ghost: '#EBF1F5',
  warning: '#880D18',
  disabled25: '#F1F2F4',
  notification: {
    infoIconBackground: '#003C71',
    infoBox: '#003C7140',
    warnIconBackground: '#FFD26C',
    warnBox: '#FFD26C7F',
    dangerIconBackground: '#880D18',
    dangerBox: '#FAC2B5'
  },
  opacity: {
    placeholder: 0.6,
  },
  background: {
    primary: '#D9E2EA'
  },
  border: {
    gray: '#7F9DB8',
    darkGray: '#979797',
    blueGray: '#BFCEDB',
    brightBlue: '#4A90E2',
    white: '#D9E3EC',
  },
  breakpoints: {
    tablet: '1100px',
    tabletMax: '1099px'
  },
  zIndex: {
    auto: 'auto',
    base: 0,
    docked: 50, // Inner content scrolling with user
    sidebar: 100,
    header: 150,
    overlay: 1100, // Over all other content, but under modal
    modal: 1200,
    popover: 1400,
    toast: 1500,
    tooltip: 1600,
  },
  boxShadow: {
    dropdown: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  status: {
    green: '#73C6A1',
    blue: '#4EC3E0',
    red: '#F5866C',
    yellow: '#FFD26C',
    purple: '#B884CB',
    pink: '#FD92A3'
  },
};

/* Alpha percentage in hex for colors:
    75%: BF , 50%: 80 , 25%: 40, 15%: 26 */


// TODO: Clean up overriding properties after using base theme
// TODO: Convert to typescript and add type declarations file
export const theme = {
  ...baseTheme,
  sanMarino: '#3F6C97',
  sanMarino25: '#3F6C9740',
  blueGray: '#BFCEDB',
  lightGreen80: '#98d7bb',
  lightGreen50: '#73C6A180',
  lightGreen25: '#73C6A140',
  lightGreen10: '#73C6A126',
  mediumGreen: '#3A8D68',
  mediumRed50: '#FDA995',
  mediumRed75: '#FDA995BF',
  mediumYellow50: '#FFD26C80',
  mediumYellow75: '#FFD26CBF',
  pink: '#FD92A3',
  mediumRed: '#F5866C',
  cyan: '#2E827E',
  border: {
    ...baseTheme.border,
    sanMarino: '#3F6C97',
    lightGreen: '#64C698',
    lightRed: '#FE8E9C',
    blue: '#46C0DA',
    yellow: '#FFCE74'
  },
  background: {
    primary: '#E5EBF0'
  },
  status: {
    ...baseTheme.status,
    lightRed: '#fce1da',
    darkRed: '#880d18',
    orange: '#f7a48f',
    solitude: '#f1f3f6'
  },
  portal: {
    maxWidth: '1800px',
    contentPadding: '0 2rem'
  },
  header: {
    height: '100px',
    scrolledHeight: '48px',
    scrolledHeightValue: 48
  }
};